import ModelSelect from '@/components/ExercisesContent/components/model-select/model-select.vue'
import ReportError from '@/components/ExercisesContent/components/report-error/report-error.vue'
import ExercisesSubtitles from '@/components/ExercisesSubtitles/ExercisesSubtitles.vue'
import GreContent from '@/components/ExercisesContent/components/gre-content/gre-content.vue'
import PracticeContent from '@/components/ExercisesContent/components/practice-content/practice-content.vue'
// import WalkmanPracticeContent from '@/components/ExercisesContent/components/practice-content/practice-content-walkman.vue'
import PassDialog from '@/components/ExercisesContent/components/pass-dialog.vue'
import AskQuestions from '@/components/ExercisesTabs/components/ask-questions/ask-questions.vue'
import VideoDialog from '@/components/VideoDialog/VideoDialog.vue'
import SubmutBtn from './components/submitBtn'
import Audio from '@/components/Audio/Audio.vue'
import Button from '@/components/button'
import Func from './components/practice-content/func'
import VipCount from './components/vipCount'
import LeadWay from './leadWay'
import CollectDialog from './collectDialog'
import Share from '@/components/share/gre'
import { TAG_COLOR_MAP } from '@/database/tagColor'

import { apiWebCollect, apiWebDelCollect } from '@/api/api.js'

export default {
  components: {
    ModelSelect,
    ReportError,
    VideoDialog,
    ExercisesSubtitles,
    PracticeContent,
    // WalkmanPracticeContent,
    GreContent,
    PassDialog,
    AskQuestions,
    Button,
    Audio,
    LeadWay,
    SubmutBtn,
    Func,
    VipCount,
    CollectDialog,
    Share
  },
  props: {
    id: {
      type: [Number, String]
    },
    detail: {
      type: Object,
      default: () => {}
    },
    number: {
      type: Object,
      default: () => {}
    },
    idList: {
      type: Object,
      default: () => {}
    },
    isPractice: {
      type: Boolean
    },
    isJobType: {
      type: Boolean
    },
    typeInfo: {
      type: Object,
      default: () => {}
    },
    filters: {
      type: Object,
      default: () => {}
    },
    search: {
      type: Function,
      default: () => {}
    },
    isDetail: {
      type: String
    },
    claId: null,
    chapterId: null,
    loading: {
      type: Boolean
    },
    greId: {
      type: [Number, String]
    },
    saveSuccess: {
      type: Function,
      default: () => {}
    },
    startRecorder: {
      type: Function,
      default: () => {}
    },
    currentIndex: {
      type: Number
    }
  },
  data() {
    return {
      checkList: [],
      showErrorDialog: false,
      showVideoDialog: false,
      showPassDialog: false,
      showSubtitles: false,
      collected: false,
      collectedLoading: false,
      checkOkButton: false,
      isSubmit: false,
      had_exam_cnt: 0,
      submitLoading: false,
      colorMap: TAG_COLOR_MAP,

      showAskDialog: false
    }
  },
  watch: {
    detail: {
      immediate: true,
      handler(val) {
        this.collected = Object.keys(this.detail.collect_cate).length > 0
        this.checkOkButton = this.isPractice ? !!val.has_exam : !!val.check
        this.showSubtitles = false
        this.submitLoading = false
        this.had_exam_cnt = this.detail.had_exam_cnt
      }
    }
  },
  computed: {
    showSubtitlesbtn() {
      // typeId
      //   ['RL', 'SST', 'MCM', 'MCS', 'SMW', 'HCS'].includes(this.typeInfo.type)
      return (
        this.isPractice &&
        [39, 28, 29, 32, 33, 31].includes(this.typeInfo.typeId)
      )
    },
    i18n() {
      return this.$t('messages')
    },
    hideTitle() {
      return ['RS', 'ASQ', 'WFD'].includes(this.typeInfo.type)
    }
  },
  methods: {
    handleSubmitLoading(val) {
      this.submitLoading = val
    },
    handleSubmit(val) {
      this.isSubmit = val
    },
    submit() {
      this.$refs.practice.submitQuestion()
    },
    handleAskDialog() {
      this.showAskDialog = !this.showAskDialog
    },
    handleErrorDialog() {
      this.showErrorDialog = !this.showErrorDialog
    },
    handleVideoDialog() {
      this.showVideoDialog = !this.showVideoDialog
    },
    handlePassDialog() {
      this.showPassDialog = !this.showPassDialog
    },
    handleSubtitles() {
      this.showSubtitles = !this.showSubtitles
    },
    selectItem(type) {
      this.$emit('selectItem', type)
    },
    handleCheckOk() {
      this.had_exam_cnt = this.had_exam_cnt + 1
      this.checkOkButton = true
      this.$layer.msg("<div class='success-icon'></div><div>确认考过成功</div>")
    },
    handleCollect() {
      this.$refs.collect.open()

      // if (this.collectedLoading) {
      //   return
      // }
      // this.collectedLoading = true
      // if (this.collected) {
      //   apiWebDelCollect({
      //     aid: this.idList.current,
      //     paramerid: this.idList.current,
      //     type: this.isPractice ? 'practice' : 'gremat'
      //   })
      //     .then((res) => {
      //       if (res.code != 200) {
      //         return
      //       }
      //       this.$layer.msg(
      //         "<div class='success-icon'></div><div>已取消收藏</div>"
      //       )
      //       this.collected = !this.collected
      //     })
      //     .finally(() => {
      //       this.collectedLoading = false
      //     })
      // } else {
      //   apiWebCollect({
      //     aid: this.idList.current,
      //     paramerid: this.idList.current,
      //     type: this.isPractice ? 'practice' : 'gremat'
      //   })
      //     .then((res) => {
      //       if (res.code != 200) {
      //         return
      //       }
      //       this.$layer.msg("<div class='success-icon'></div><div>已收藏</div>")
      //       this.collected = !this.collected
      //     })
      //     .finally(() => {
      //       this.collectedLoading = false
      //     })
      // }
    },
    handleFuncChange(type, value) {
      if (this.$refs.practice) {
        this.$refs.practice.setFuncValue(type, value)
      }
    },
    handleShare() {
      this.$refs.share.open(this.detail)
    },
    handleAudioEnd(type) {
      this.$emit('selectItem', type)
    }
  }
}
